import Vue from 'vue'
import Api from '@/services/api'
import { remove } from 'lodash'

function FuserClientException(message) {
  this.message = message
  this.name = 'FuserClientException'
}

export default {
  namespaced: true,
  state: () => ({
    clients: []
  }),
  mutations: {
    LOAD_ALL_CLIENTS(state, clients) {
      state.clients = [...clients]
    },
    ADD_CLIENT(state, client) {
      state.clients.push(client)
    },
    UPDATE_CLIENT(state, client) {
      const index = state.clients.findIndex((u) => u._id === client._id)

      Vue.set(state.clients, index, client)
    },
    DELETE_CLIENT(state, client) {
      const clients = remove(state.clients, (u) => u._id === client._id)

      state.clients = clients
    },
    RESET(state) {
      state.clients = []
    }
  },
  actions: {
    async loadAllClients({ commit }) {
      let clients = []

      try {
        const { data: result } = await Api().get('/client')

        clients = result.data
      } catch (error) {
        // failed to get clients
      }

      commit('LOAD_ALL_CLIENTS', clients)
    },
    async createClient({ commit }, client) {
      try {
        const { data: result } = await Api().post('/client', client)

        if (!result.data) {
          throw new FuserClientException(
            `Client not created: ${result.message}`
          )
        }
        commit('ADD_CLIENT', result.data)
        return result
      } catch (error) {
        if (error instanceof FuserClientException) return { error }
        return { error: Error('Client not created') }
      }
    },
    async updateClient({ commit }, client) {
      try {
        const { data: result } = await Api().put('/client/update', client)

        if (!result.data) {
          throw new FuserClientException(
            `Client not updated: ${result.message}`
          )
        }
        commit('UPDATE_CLIENT', result.data)
        return result
      } catch (error) {
        if (error instanceof FuserClientException) return { error }
        return { error: Error('Client not updated') }
      }
    },
    async updateApiKey({ commit }, client) {
      try {
        const body = {
          client,
          sauce: process.env.VUE_APP_SERVER_SAUCE
        }
        body.client.api_key = client.new_api_key

        const { data: result } = await Api().put('/client/reset_api_key', body)

        if (!result.data) {
          throw new FuserClientException(
            `Client not updated: ${result.message}`
          )
        }
        commit('UPDATE_CLIENT', result.data)
      } catch (error) {
        if (error instanceof FuserClientException) return { error }
        return { error: Error('Client not updated') }
      }
    },
    async deleteClient({ commit }, client) {
      try {
        const { data: result } = await Api().delete(`/client/${client._id}`)

        if (!result.data) {
          throw new FuserClientException(
            `Client not deleted: ${result.message}`
          )
        }
        commit('DELETE_CLIENT', result.data)
      } catch (error) {
        if (error instanceof FuserClientException) return { error }
        return { error: Error('Client not deleted') }
      }
    }
  },
  getters: {
    getClient: (state) => (clientId) => {
      // eslint-disable-next-line eqeqeq
      const client = state.clients.find((client) => client._id == clientId)

      return client
    },
    getClients(state) {
      return state.clients
    }
  }
}
