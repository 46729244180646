<template>
  <v-app-bar app clipped-left color="blue darken-4" dark>
    <v-app-bar-nav-icon v-if="!drawer" @click="showDrawer"></v-app-bar-nav-icon>

    <v-toolbar-title
      style="width: 180px"
      class="hidden-sm-and-down ml-0 mr-1 pl-3 pr-6 clickable"
      @click="goHome"
    >
      <v-img alt="FotoKem" src="@/assets/fk-main-rgb@3x.png" />
    </v-toolbar-title>

    <v-divider vertical />

    <v-btn text @click="goHome" class="subtitle">
      <span class="title ml-5 mr-5">fUSER</span>
    </v-btn>

    <v-spacer></v-spacer>

    <div v-if="user">
      <v-menu left bottom>
        <template #activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item>
            <v-list-item-title>{{ user.name }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item @click="logout">
            <v-list-item-title>
              <v-icon>mdi-logout</v-icon>
              Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
  </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderComponent',
  props: {
    drawer: {
      type: Boolean,
      required: true
    },
    showDrawer: {
      type: Function,
      required: true
    }
  },
  computed: {
    ...mapGetters({ user: 'getSessionUser' })
  },
  methods: {
    async logout() {
      await this.$store.dispatch('breadcrumb/reset', {})
      await this.$store.dispatch('logout', {})

      this.$router.push('/login')
    },
    goHome() {
      const newRoutePath = '/fuser'

      if (this.$route.path !== newRoutePath) {
        this.$router.push(newRoutePath)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.subtitle {
  text-transform: none;
}

.clickable {
  cursor: pointer;
}
</style>
