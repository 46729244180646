import Vue from 'vue'

export default {
  namespaced: true,

  state: () => ({
    breadcrumbs: []
  }),
  mutations: {
    ADD_BREADCRUMB(state, { breadcrumb, level }) {
      state.breadcrumbs.forEach((existing) => {
        existing.disabled = false
      })

      breadcrumb.disabled = true

      if (level) {
        Vue.set(state.breadcrumbs, level, breadcrumb)
      } else {
        state.breadcrumbs.push(breadcrumb)
      }
    },
    RESET_BREADCRUMB(state, { text, routeComponent }) {
      if (text) {
        state.breadcrumbs = [
          {
            text,
            disabled: false,
            exact: true,
            to: { name: routeComponent }
          }
        ]
      } else {
        state.breadcrumbs = []
      }
    }
  },
  actions: {
    add({ commit }, { breadcrumb, level }) {
      commit('ADD_BREADCRUMB', { breadcrumb, level })
    },
    reset({ commit }, { text, routeComponent }) {
      commit('RESET_BREADCRUMB', { text, routeComponent })
    }
  }
}
