<template>
  <v-app>
    <Header :drawer="drawer" :showDrawer="showDrawer" />

    <v-navigation-drawer
      v-model="drawer"
      app
      clipped
      color="grey lighten-4"
      width="270"
      v-if="user"
    >
      <v-list nav>
        <v-list-item-group color="blue darken-4">
          <v-list-item v-for="(link, i) in links" :key="i" :to="link.path">
            <v-list-item-icon>
              <v-icon>{{ link.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ link.searchTerm }} {{ link.title }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action v-if="getSearchInfo(link.title)">
                <v-list-item-action-text>
                  <v-icon
                    color="grey lighten-1"
                    x-small
                    left
                  >
                    mdi-magnify
                  </v-icon>{{ getSearchInfo(link.title) }}
                </v-list-item-action-text>
              </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-main>
      <v-container class="px-10">
        <router-view />

        <v-snackbar
          v-for="(snackbar, index) in snackbars.filter((s) => s.visible)"
          :key="snackbar.text + Math.random()"
          :value="snackbar.visible"
          @input="closeSnackbar(snackbar)"
          :bottom="true"
          :color="snackbar.color"
          :timeout="snackbar.timeout"
          :style="`bottom: ${index * 70 + 8}px`"
        >
          {{ snackbar.text }}
          <v-btn dark text @click="closeSnackbar(snackbar)"> Close </v-btn>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
// eslint-disable-next-line
import Header from '@/components/Header.vue'

export default {
  components: {
    // eslint-disable-next-line
    Header
  },
  data() {
    return {
      drawer: true,
      links: [
        {
          title: 'Users',
          path: { name: 'User' },
          icon: 'mdi-account-multiple'
        },
        {
          title: 'Clients',
          path: { name: 'Clients' },
          icon: 'mdi-devices'
        },
        {
          title: 'Organizations',
          path: { name: 'Organizations' },
          icon: 'mdi-domain'
        }

      ]
    }
  },
  computed: {
    ...mapState('snackbar', {
      snackbars: (state) => state.snackbars
    }),
    ...mapGetters({ user: 'getSessionUser' }),
    ...mapGetters('settings', ['getUsersSearchTerm', 'getClientsSearchTerm', 'getOrganizationsSearchTerm'])
  },
  methods: {
    closeSnackbar(snackbar) {
      this.$store.dispatch('snackbar/close', snackbar)
    },
    showDrawer() {
      this.drawer = true
    },
    getSearchInfo(item) {
      if (item === 'Users') return this.getUsersSearchTerm
      if (item === 'Clients') return this.getClientsSearchTerm
      if (item === 'Organizations') return this.getOrganizationsSearchTerm
      return null
    }
  }
}
</script>
<style lang="scss">
  .v-list.striped .v-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
</style>
