import Vue from 'vue'
import router from '@/router'

export default {
  state: () => ({
    sessionUser: null,
    expiredSession: false
  }),
  mutations: {
    SET_SESSION_USER(state, user) {
      // session expiration
      Vue.$cookies.set('token', user.token, user.expires_in)

      state.sessionUser = user
    },
    SET_EXPIRED_SESSION(state, expiredSession) {
      state.expiredSession = expiredSession
    },
    LOGOUT_SESSION_USER(state, { redirect, expiredSession }) {
      Vue.$cookies.remove('token')

      state.sessionUser = null
      state.expiredSession = expiredSession

      if (redirect) {
        router.go(0)
      }
    }
  },
  actions: {
    setLogoutTimer({ dispatch, state }, expirationTime) {
      setTimeout(() => {
        if (state.sessionUser !== null) {
          dispatch('logout', { redirect: true, expiredSession: true })
        }
      }, expirationTime * 1000)
    },
    setSessionUser({ commit, dispatch }, user) {
      dispatch('setLogoutTimer', user.expires_in)
      dispatch('setExpiredSession', false)

      commit('SET_SESSION_USER', user)
    },
    setExpiredSession({ commit }, expiredSession) {
      commit('SET_EXPIRED_SESSION', expiredSession)
    },
    logout({ commit }, { redirect, expiredSession }) {
      commit('LOGOUT_SESSION_USER', { redirect, expiredSession })
      commit('fuser/RESET')
      commit('client/RESET')
      commit('settings/RESET')
    }
  },
  getters: {
    getSessionUser(state) {
      return state.sessionUser
    },
    getRole(state) {
      return state.sessionUser.system_role
    },
    canEdit(state) {
      const allowedroles = ['admin', 'security', 'hr']
      return allowedroles.includes(state.sessionUser.system_role.toLowerCase())
    },
    isAdmin(state) {
      return state.sessionUser.system_role.toLowerCase() === 'admin'
    },
    getExpiredSession(state) {
      return state.expiredSession
    }
  }
}
