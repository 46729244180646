// import Vue from 'vue'
import Api from '@/services/api'
import { remove } from 'lodash'

function FuserException(message, error) {
  this.message = message
  this.name = 'FuserException'
  this.error = error
}
export default {
  namespaced: true,
  state: () => ({
    users: []
  }),
  mutations: {
    LOAD_ALL_USERS(state, users) {
      state.users = [...users]
    },
    ADD_USER(state, user) {
      state.users.push(user)
    },
    UPDATE_USER(state, user) {
      const index = state.users.findIndex((u) => u._id === user._id)
      if (index !== -1) state.users[index] = user
    },
    DELETE_USER(state, user) {
      const users = remove(state.users, (u) => u._id === user._id)

      state.users = users
    },
    RESET(state) {
      state.users = []
    }
  },
  actions: {
    async loadAllUsers({ commit }) {
      let users = []

      try {
        const { data: result } = await Api().get('/user?limit=0')

        if (!result.data) {
          throw new FuserException(`Failed to load users: ${result.message}`, null)
        }

        users = result.data
        commit('LOAD_ALL_USERS', users)
      } catch (error) {
        if (error instanceof FuserException) return { error }
        return { error: Error('Failed to load users') }
      }
    },
    async createUser({ commit }, user) {
      // make sure we have needed fields
      if (!('zoneinfo' in user)) {
        user.zoneinfo = 'America/Los_Angeles'
      }
      if (!('locale' in user)) {
        user.locale = 'en-US'
      }

      try {
        const { data: result } = await Api().post('/user', user)

        if (!result.data) {
          throw new FuserException(result.message, null)
        }
        commit('ADD_USER', result.data)
        return result
      } catch (error) {
        if (error instanceof FuserException) return { error }
        return { error: Error('User not saved') }
      }
    },
    async updateUser({ commit }, user) {
      try {
        const { data: result } = await Api().put('/user/update', user)

        if (!result.data) {
          throw new FuserException(`User not updated: ${result.message}`, null)
        }
        commit('UPDATE_USER', result.data)
        return result
      } catch (error) {
        if (error instanceof FuserException) return { error }
        return { error: Error('User not updated') }
      }
    },
    async updatePassword({ commit }, user) {
      try {
        const { data: result } = await Api().put('/user/update/password', user)

        if (!result.data) {
          throw new FuserException(`Password not updated: ${result.message}`, result.errors)
        }
        commit('UPDATE_USER', result.data)
        return result
      } catch (error) {
        if (error instanceof FuserException) return { error }
        return { error: Error('Password not updated') }
      }
    },
    async deleteUser({ commit }, user) {
      try {
        const { data: result } = await Api().delete(`/user/${user._id}`)
        if (!result.data) {
          throw new FuserException(`User not deleted: ${result.message}`, null)
        }
        commit('DELETE_USER', result.data)
        return result
      } catch (error) {
        if (error instanceof FuserException) return { error }
        return { error: Error('User not deleted') }
      }
    },
    async reset2FACode({ _ }, user) {
      try {
        const { data: result } = await Api().delete(`/user/2fa/${user._id}`)
        if (!result.data) {
          throw new FuserException(`2FA not deleted: ${result.message}`, null)
        }
      } catch (error) {
        if (error instanceof FuserException) return { error }
        return { error: Error('2FA not deleted') }
      }
    }
  },
  getters: {
    getUser: (state) => (userId) => {
      // eslint-disable-next-line eqeqeq
      const user = state.users.find((user) => user._id == userId)

      return user
    },
    getUsers(state) {
      return state.users
    }
  }
}
