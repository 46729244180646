import axios from 'axios'
import store from '@/store'
import router from '@/router'

export default () => {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_SERVER_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })

  if (store.state.sessionUser.sessionUser) {
    instance.defaults.headers.common.Authorization =
      store.state.sessionUser.sessionUser.token
  }

  // add a response interceptor that will log user out if 401 error is recieved
  // or redirect users to unauthorized page if 403 error is recieved
  instance.interceptors.response.use(
    (response) => {
      return Promise.resolve(response)
    },
    (error) => {
      // check the error
      if (error.response.status === 401 && error.config.url !== '/login') {
        store.dispatch('logout', { redirect: true, expiredSession: true })
      }

      if (error.response.status === 403) {
        router.push({ name: 'Unauthorized' })
      }

      return Promise.reject(error)
    }
  )

  return instance
}
