export default {
  namespaced: true,
  state: () => ({
    tableItemsPerPage: 10,
    usersSearchTerm: '',
    clientsSearchTerm: '',
    organizationsSearchTerm: ''
  }),
  mutations: {
    SET_TABLE_ITEMS_PER_PAGE(state, tableItemsPerPage) {
      state.tableItemsPerPage = tableItemsPerPage
    },
    SET_USERS_SEARCH_TERM(state, usersSearchTerm) {
      state.usersSearchTerm = usersSearchTerm
    },
    SET_CLIENTS_SEARCH_TERM(state, clientsSearchTerm) {
      state.clientsSearchTerm = clientsSearchTerm
    },
    SET_ORGANIZATIONS_SEARCH_TERM(state, organizationsSearchTerm) {
      state.organizationsSearchTerm = organizationsSearchTerm
    },
    RESET(state) {
      state.usersSearchTerm = ''
      state.clientsSearchTerm = ''
      state.organizationsSearchTerm = ''
    }
  },
  actions: {
    setTableItemsPerPage({ commit }, tableItemsPerPage) {
      commit('SET_TABLE_ITEMS_PER_PAGE', tableItemsPerPage)
    },
    setUsersSearchTerm({ commit }, usersSearchTerm) {
      commit('SET_USERS_SEARCH_TERM', usersSearchTerm)
    },
    setClientsSearchTerm({ commit }, clientsSearchTerm) {
      commit('SET_CLIENTS_SEARCH_TERM', clientsSearchTerm)
    },
    setOrganizationsSearchTerm({ commit }, organizationsSearchTerm) {
      commit('SET_ORGANIZATIONS_SEARCH_TERM', organizationsSearchTerm)
    }
  },
  getters: {
    getTableItemsPerPage(state) {
      return state.tableItemsPerPage
    },
    getUsersSearchTerm(state) {
      return state.usersSearchTerm
    },
    getClientsSearchTerm(state) {
      return state.clientsSearchTerm
    },
    getOrganizationsSearchTerm(state) {
      return state.organizationsSearchTerm
    }
  }
}
