import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import snackbarModule from './snackbar'
import breadcrumbModule from './breadcrumb'
import fuserModule from './fuser'
import sessionUserModule from './session-user'
import settingsModule from './settings'
import clientModule from './client'
import organizationModule from './organization'

Vue.use(Vuex)

export default new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  plugins: [createPersistedState()],
  modules: {
    snackbar: snackbarModule,
    breadcrumb: breadcrumbModule,
    fuser: fuserModule,
    sessionUser: sessionUserModule,
    settings: settingsModule,
    client: clientModule,
    organization: organizationModule
  },
  state: {},
  mutations: {},
  actions: {}
})
