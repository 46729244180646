import Vue from 'vue'
import Api from '@/services/api'
import { remove } from 'lodash'

function FuserOrganizationException(message) {
  this.message = message
  this.name = 'FuserOrganizationException'
}

export default {
  namespaced: true,
  state: () => ({
    organizations: []
  }),
  mutations: {
    LOAD_ALL_ORGANIZATIONS(state, organizations) {
      state.organizations = [...organizations]
    },
    ADD_ORGANIZATION(state, organization) {
      state.organizations.push(organization)
    },
    UPDATE_ORGANIZATION(state, organization) {
      const index = state.organizations.findIndex((u) => u._id === organization._id)

      Vue.set(state.organizations, index, organization)
    },
    DELETE_ORGANIZATION(state, organization) {
      const organizations = remove(state.organizations, (u) => u._id === organization._id)

      state.organizations = organizations
    },
    RESET(state) {
      state.organizations = []
    }
  },
  actions: {
    async loadAllOrganizations({ commit }) {
      let organizations = []

      try {
        const { data: result } = await Api().get('/organization')

        organizations = result.data
      } catch (error) {
        // failed to get organizations
      }

      commit('LOAD_ALL_ORGANIZATIONS', organizations)
    },
    async createOrganization({ commit }, organization) {
      try {
        const { data: result } = await Api().post('/organization', organization)

        if (!result.data) {
          throw new FuserOrganizationException(
            `Organization not created: ${result.message}`
          )
        }
        commit('ADD_ORGANIZATION', result.data)
        return result
      } catch (error) {
        if (error instanceof FuserOrganizationException) return { error }
        return { error: Error('Organization not created') }
      }
    },
    async updateOrganization({ commit }, organization) {
      try {
        const { data: result } = await Api().put('/organization/update', organization)

        if (!result.data) {
          throw new FuserOrganizationException(
            `Organization not updated: ${result.message}`
          )
        }
        commit('UPDATE_ORGANIZATION', result.data)
        return result
      } catch (error) {
        if (error instanceof FuserOrganizationException) return { error }
        return { error: Error('Organization not updated') }
      }
    },
    async deleteOrganization({ commit }, organization) {
      try {
        const { data: result } = await Api().delete(`/organization/${organization._id}`)

        if (!result.data) {
          throw new FuserOrganizationException(
            `Organization not deleted: ${result.message}`
          )
        }
        commit('DELETE_ORGANIZATION', result.data)
      } catch (error) {
        if (error instanceof FuserOrganizationException) return { error }
        return { error: Error('Organization not deleted') }
      }
    }
  },
  getters: {
    getOrganization: (state) => (organizationId) => {
      // eslint-disable-next-line eqeqeq
      const organization = state.organizations.find((organization) => organization._id == organizationId)

      return organization
    },
    getOrganizations(state) {
      return state.organizations
    }
  }
}
